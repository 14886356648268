import { useFilter } from '@/utils/hooks.js'
import { companySelect, maintenanceSelect } from '@/api/vehicle.js'
import { getStore } from '@/utils/store'

const userInfo = getStore({ name: 'userInfo' })

// 公司下拉列表
export async function fetchCompanyList() {
	const { err, data } = await useFilter(() => companySelect(true), {
		name: 'companyName',
		code: 'companyId',
	})
	if (err) return
	return data
}

// 司服伙伴下拉列表
export async function fetchDriverServiceList() {
	const { companyId } = userInfo
	const arr = companyId ? companyId.split(',') : []
	const { err, data } = await useFilter(
		() => maintenanceSelect('DriverService', arr, { isAll: true }),
		{
			name: 'realName',
			code: 'id',
		},
	)
	if (err) return
	return data
}

// 维保专员下拉列表
export async function fetchMaintainer() {
	const { companyId } = userInfo
	const arr = companyId ? companyId.split(',') : []
	const { err, data } = await useFilter(() => maintenanceSelect('Mainten', arr, { isAll: true }), {
		name: 'realName',
		code: 'id',
	})
	if (err) return
	return data
}
