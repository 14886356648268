import { fetchCompanyList } from '@/utils/common'
export const columns = [
	{
		prop: 'companyName',
		label: '所属公司',
		width: '300px',
	},
	{
		prop: 'status',
		label: '延期状态',
		width: '160px',
	},
	{
		prop: 'orderNo',
		label: '合同ID',
		width: '220px',
	},
	{
		prop: 'carNumber',
		label: '车牌号',
		width: '160px',
	},
	{
		prop: 'monthlyRent',
		label: '合同月租',
		width: '160px',
	},
	{
		prop: 'delayDays',
		label: '延期天数',
		width: '160px',
	},
	{
		prop: 'delayRent',
		label: '延期租金',
		width: '150px',
	},
	{
		prop: 'isPaid',
		label: '延期类型',
		width: '160px',
	},
	{
		prop: 'delayReason',
		label: '延期原因',
		width: '200px',
	},
	{
		prop: 'delayRemark',
		label: '原因备注',
		width: '320px',
	},
	{
		prop: 'createTime',
		label: '延期时间',
		width: '240px',
	},
	{
		prop: 'createUserName',
		label: '操作人',
		width: '120px',
	},
]
export const setting = {
	expand: true,
	filters: [
		{
			placeholder: '请选择所属公司',
			label: '所属公司',
			attr: 'companyIds',
			type: 'multi-select',
			width: 300,
			options: fetchCompanyList,
		},
		{
			placeholder: '输入合同ID或车牌号',
			label: '合同信息',
			attr: 'keyword',
			type: 'search',
		},
		{
			placeholder: '请选择延期时间',
			label: '延期时间',
			attr: 'createTime',
			type: 'date',
		},
	],
}

export const form = [
	{
		prop: 'driverNo',
		label: '司机ID',
		component: 'el-input',
		attrs: { placeholder: '请输入司机ID' },
	},
	{
		prop: 'companyId',
		label: '所属公司',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择所属公司' },
	},
	{
		prop: 'name',
		label: '司机姓名',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入司机姓名' },
	},
	{
		prop: 'mobile',
		label: '手机号',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入司机手机号' },
	},
	{
		prop: 'idCard',
		label: '身份证号',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入身份证号' },
	},
	{
		prop: 'sex',
		label: '性别',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择性别' },
	},
	{
		prop: 'idCardAddress',
		label: '身份证地址',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入身份证地址' },
	},
	{
		prop: 'birthday',
		label: '出生日期',
		required: true,
		custom: true,
	},
	{
		prop: 'presentAddress',
		label: '现居住地址',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入现居住地址' },
	},
	{
		prop: 'contactPersonName',
		label: '紧急联系人',
		component: 'el-select',
		options: [],
	},
	{
		prop: 'contactPersonMobile',
		label: '联系人手机',
		component: 'el-input',
		attrs: { placeholder: '请输入联系人手机' },
	},
	{
		prop: 'drivingLicenseDate',
		label: '驾驶证日期',
		custom: true,
	},
]

export const uploadForm = [
	{
		prop: 'idCardPic',
		required: true,
		label: '身份证',
		custom: true,
	},
	{
		prop: 'drivingLicensePic',
		label: '驾驶证',
		component: 'yi-file-uploader',
		required: true,
		cWidth: 500,
		attrs: {
			accept: '.jpg,.jpeg,.png,.bmp',
			max: 5,
			message: '请上传驾驶证',
			productType: 'renrenjia',
			sourceType: 'image',
			uploaderSize: 20,
		},
	},
	{
		prop: 'certificateProfessionalPic',
		label: '从业资格证',
		component: 'yi-file-uploader',
		cWidth: 500,
		attrs: {
			accept: '.jpg,.jpeg,.png,.bmp',
			max: 5,
			message: '请上传从业资格证',
			productType: 'renrenjia',
			sourceType: 'image',
			uploaderSize: 20,
		},
	},
]
