<template>
	<div class="container">
		<yi-list-layout :loading="isLoading">
			<template #title>
				<div class="header-title">合同延期</div>
				<div class="header-button">
					<el-button
						:loading="buttonLoading"
						@click="onExport"
						v-permission="'pc/driver-center/order-delay/export'"
					>
						导出Excel
					</el-button>
				</div>
			</template>
			<template #filter>
				<yi-filter ref="filterRef" :setting="settingRef" @change="onChangeFilter" />
			</template>
			<template #table>
				<yi-table
					ref="yiTableRef"
					table-height="100%"
					:columns="columns"
					:data="tableData"
					:params="{ page }"
					autoWidth
				>
					<template #status="{ value }">
						{{ mapDict('order_delay_status', value) }}
					</template>
					<template #isPaid="{ value }">
						{{ value ? '有偿延期' : '无偿延期' }}
					</template>
				</yi-table>
			</template>
			<template #footer>
				<yi-pagination
					:current-page="page.current"
					:page-size="page.size"
					:total-count="totalCount"
					@page-change="onPageChange"
					@size-change="onSizeChange"
				/>
			</template>
		</yi-list-layout>
	</div>
</template>

<script>
import { defineComponent } from 'vue'
import { exportOrderDelay, orderDelayList } from '@/api/driver'
import { handleExport } from '@/utils/util'
export default defineComponent({
	name: 'OrderDelayList',
})
</script>

<script setup name="RenewalList">
import { inject, ref, onMounted } from 'vue'
import { setting, columns } from './config'
import { useState, useFetch, useDict } from '@/utils/hooks.js'

const $message = inject('$message')

const settingRef = ref(setting)

const [params, setParams] = useState({})
const [totalCount, setTotal] = useState(0)
const [tableData, setTable] = useState([])
const { isLoading, fetchData } = useFetch()
const [dict, setDict] = useState({})
const [buttonLoading, setButtonLoading] = useState(false)
const [page, setPage] = useState({ current: 1, size: 20 })

async function fetchList() {
	const payload = { ...params.value, page: page.value }
	const service = orderDelayList
	const { data, err } = await fetchData(service, payload)
	if (err) {
		$message.error(err.message)
	} else {
		setTable(data.records)
		setTotal(data.total)
	}
}

function onSizeChange(size) {
	setPage({ current: 1, size })
	fetchList()
}
function onPageChange(current) {
	const { size } = page.value
	setPage({ current, size })
	fetchList()
}

function onChangeFilter(event) {
	setParams(event)
	fetchList()
}
async function onExport() {
	setButtonLoading(true)
	try {
		const res = await exportOrderDelay(params.value)
		if (res) handleExport(res, '合同延期')
	} catch (err) {
		$message.error(err.message)
	} finally {
		setButtonLoading(false)
	}
}
async function fetchDict() {
	const dict = await useDict('order_delay_status')
	setDict(dict)
}
function mapDict(key, value) {
	const e = dict.value[key]
	if (!e) return '--'
	const target = e.find(item => item.code == value)

	return target ? target.name : '--'
}
function init() {
	fetchDict()
	fetchList()
}

onMounted(() => {
	init()
})
</script>

<style lang="less" scoped>
.container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.header-title {
		height: 54px;
		line-height: 54px;
		overflow: hidden;
		float: left;
	}
	.header-button {
		margin: 11px 0;
		float: right;
	}
}
.click {
	cursor: pointer;
	color: #409eff;
}
</style>
